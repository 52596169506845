.ContainerModal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.25);
}
.ContainerModal::-webkit-scrollbar {
  display: none;
}
.ContainerModalContent {
  width: 40%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  background: #ffffff;
  border-bottom: 4px solid #20f092;
  color: #262626;
  padding: 2% 0 3% 3%;
}
.ContainerModalContentContainer {
  width: 95%;
  height: 100%;
}
.ContainerModalCloseContainer {
  display: flex;
  justify-content: flex-end;
  width: 95%;
}
.close {
  width: 18px;
  height: 18px;
}
.close:hover {
  cursor: pointer;
}

/* INNER MODALS */
.VerifyModalContainer {
  width: 100%;
  height: 100%;
}
.VerifyModalTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 51px;
  line-height: 60px;
  margin-bottom: 20px;
}
.VerifyModalMessage {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}
.VerifyModalSubtitle {
  margin-bottom: 20px;
}
.VerifyParameters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  margin-bottom: 20px;
}
.VerifyParametersApprove {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 190px;
  margin-bottom: 20px;
}
.VerifyParametersFund {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
}
.bolded {
  font-weight: 700;
}
.VerifiedButton {
  min-width: fit-content;
  max-width: 60%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  border: 2px solid #20f092;
  background: transparent;
  color: #262626;
  user-select: none;
  -webkit-user-select: none;
  text-decoration: none;
}
.ApproveMessage {
  margin-top: 10px;
}
.VerifyFundParameter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
}
.VerifyModalButton {
  min-width: 100px;
  max-width: fit-content;
  width: -moz-fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: #20f092;
  color: #262626;
  user-select: none;
  -webkit-user-select: none;
  text-decoration: none;
}
.VerifyModalButton:hover {
  cursor: pointer;
}
.CheckLivenessModalButton {
  min-width: 100px;
  max-width: fit-content;
  width: -moz-fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  margin-bottom: 25px;
  background: #20f092;
  color: #262626;
  user-select: none;
  -webkit-user-select: none;
  text-decoration: none;
}
.CheckLivenessModalButton:hover {
  cursor: pointer;
}
