@media screen and (max-width: 786px) {
  .App{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #262626;
    color: #ffffff;
    overflow-y: scroll;
  }
}

@media (min-width: 786px) {
  .App {
    width: 100%;
    min-height: 100vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(45deg,  #1f1f1f, #242424, #363535);
    color: #ffffff;
    font-family: 'Inconsolata', monospace;
  }

  .HeroContainer {
    width: 92vw;
    height: 100%;
    min-height: 80vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}